import TYPES from '@/types';
import { requiredRule } from '@/vue-app/utils/form-rules';

// Application
import CreateSupportTicketCommand from '@/modules/zendesk/support-ticket/application/commands/create-support-ticket-command';

// Domain
import { SupportTicketDto } from '@/modules/zendesk/support-ticket/domain/dtos/support-ticket-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class SupportWidgetCommentViewModel {
  @Inject(TYPES.CREATE_SUPPORT_TICKET_COMMAND)
  private readonly create_ticket_zendesk_command!: CreateSupportTicketCommand;

  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  i18n_namespace = 'components.support-widget.support_widget_comment';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  private readonly subject = this.translate('subject');

  comment = '';

  rule_comment = [requiredRule];

  is_loading = false;

  valid_form = false;

  createTicketSupportZendesk= async () => {
    try {
      this.is_loading = true;
      const create_ticket_zendesk_dto: SupportTicketDto = {
        subject: this.subject,
        comment: this.comment,
      };
      await this.create_ticket_zendesk_command.execute(create_ticket_zendesk_dto);
      return true;
    } catch {
      return false;
    } finally {
      this.comment = '';
      this.is_loading = false;
    }
  }
}
