
















































import { Component, Vue } from 'vue-property-decorator';

import SupportWidgetCommentViewModel
  from '@/vue-app/view-models/components/support-widget/support-widget-comment-view-model';

@Component({ name: 'SupportWidgetComment' })
export default class SupportWidgetComment extends Vue {
  support_widget_wiew_model = Vue.observable(
    new SupportWidgetCommentViewModel(),
  );

  async createTicketSupportZendesk() {
    const ticket_created = await this.support_widget_wiew_model.createTicketSupportZendesk();

    if (ticket_created) {
      this.$emit('successStep');
    } else {
      this.$emit('errorStep');
    }
  }
}
